#react-portal-toast-container {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.3em 1em;
  top: 0;
  pointer-events: none;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
}

.toast {
  display: flex;
  min-width: 300px;
  max-width: 400px;
  padding: 6px 10px 6px 10px;
  border-radius: 12px;
  pointer-events: initial;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  z-index: 10000;
  position: relative;
  color: var(--chakra-colors-secondaryGray-900);
}
.toast .toast-title {
  font-size: 16px;
  font-weight: 600;
}
.toast .toast-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}
.toast .toast-icon {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
}
.toast .toast-description {
  font-size: 14px;
}
.toast.info .close-toast-button:hover {
  background: rgba(0, 109, 227, 0.2);
}
.toast.error .close-toast-button:hover {
  background: rgba(234, 78, 45, 0.2);
}
.toast.warning .close-toast-button:hover {
  background: rgba(239, 149, 0, 0.2);
}
.toast.success .close-toast-button:hover {
  background: rgba(63, 190, 96, 0.2);
}

.toast.info {
  background-image: linear-gradient(
    rgba(0, 109, 227, 0.16),
    rgba(0, 109, 227, 0.16)
  );
  border-color: rgba(0, 109, 227, 0.4);
  box-shadow: 0 2px 1em rgba(0, 109, 227, 0.3);
}
.toast.error {
  background-image: linear-gradient(
    rgba(234, 78, 45, 0.16),
    rgba(234, 78, 45, 0.16)
  );
  border-color: rgba(234, 78, 45, 0.4);
  box-shadow: 0 2px 1em rgba(234, 78, 45, 0.3);
}
.toast.warning {
  background-image: linear-gradient(
    rgba(239, 149, 0, 0.16),
    rgba(239, 149, 0, 0.16)
  );
  border-color: rgba(239, 149, 0, 0.4);
  box-shadow: 0 2px 1em rgba(239, 149, 0, 0.3);
}
.toast.success {
  background-image: linear-gradient(
    rgba(63, 190, 96, 0.16),
    rgba(63, 190, 96, 0.16)
  );
  border-color: rgba(63, 190, 96, 0.4);
  box-shadow: 0 2px 1em rgba(63, 190, 96, 0.3);
}

.toast .icon-bg-toast {
  box-shadow: 0 4px 1em rgba(0, 0, 0, 0.2);
}
.toast.info .icon-bg-toast {
  background: rgba(0, 109, 227, 1);
}
.toast.error .icon-bg-toast {
  background: rgba(234, 78, 45, 1);
}
.toast.warning .icon-bg-toast {
  background: rgba(239, 149, 0, 1);
}
.toast.success .icon-bg-toast {
  background: rgba(63, 190, 96, 1);
}

.toast.top {
  margin: 0 auto 10px;
}
.toast.top-right {
  margin: 0 0 10px auto;
}
.toast.top-left {
  margin: 0 auto 10px 0;
}

.toast.bottom {
  margin: 10px auto 0 auto;
}
.toast.bottom:first-child {
  margin: auto auto 0 auto;
}
.toast.bottom-left {
  margin: 10px auto 0 0;
}
.toast.bottom-left:first-child {
  margin: auto auto 0 0;
}
.toast.bottom-right {
  margin: 10px 0 0 auto;
}
.toast.bottom-right:first-child {
  margin: auto 0 0 auto;
}

.toast .toast-content.closable {
  max-width: calc(100% - 36px - 24px - 12px - 12px);
}
.toast .toast-content {
  max-width: calc(100% - 36px - 12px);
}

/* Dark Mode */
.dark.toast {
  color: #ffffff;
}
.dark.toast.info {
  background-image: linear-gradient(#00438a, #00438a);
  border-color: #0054ad;
  box-shadow: 0 2px 1.5em rgba(0, 109, 227, 0.5);
}
.dark.toast.error {
  background-image: linear-gradient(#b82d34, #b82d34);
  border-color: #d6575e;
  box-shadow: 0 2px 1.5em rgba(214, 87, 94, 0.5);
}
.dark.toast.warning {
  background-image: linear-gradient(#c27800, #c27800);
  border-color: #ef9500;
  box-shadow: 0 2px 1.5em rgba(239, 149, 0, 0.5);
}
.dark.toast.success {
  background-image: linear-gradient(#1d6322, #1d6322);
  border-color: #2e9e36;
  box-shadow: 0 2px 1.5em rgba(46, 158, 54, 0.5);
}
.dark.toast.info .icon-bg-toast {
  background: #006de3;
}
.dark.toast.error .icon-bg-toast {
  background: #d6575e;
}
.dark.toast.warning .icon-bg-toast {
  background: #ef9500;
}
.dark.toast.success .icon-bg-toast {
  background: #3fbe60;
}

.dark .toast .icon-bg-toast {
  box-shadow: 0 4px 1em rgba(255, 255, 255, 0.1);
}
.dark .toast .close-toast-button:hover {
  background: rgba(255, 255, 255, 0.08);
}

/*  */
