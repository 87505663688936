.slider {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 4px;
  border-radius: 5px;
}

.slider {
  background: #ddd;
  background-image: linear-gradient(#00a88450, #00a88450);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: 0.3s;
}

.slider::-webkit-slider-thumb {
  background: #00a884;
}

.slider::-webkit-slider-thumb:active {
  transform: scale(1.12);
}

.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
