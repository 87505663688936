@import url("../chat-window/message-template/audio/styles.css");

.table-row:hover {
  background: rgba(45, 61, 133, 0.03);
}

.invalid-input {
  border: 3px solid var(--chakra-colors-secondary-600) !important;
}

@keyframes presence {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
