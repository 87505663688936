.slider {
  -webkit-appearance: none;
  appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 4px;
  border-radius: 5px;
}

.slider.dark {
  background: rgba(255, 255, 255, 0.08);
  background-image: linear-gradient(
    var(--chakra-colors-primary-500),
    var(--chakra-colors-primary-500)
  );
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

.slider.light {
  background: #e2e8f0;
  background-image: linear-gradient(
    var(--chakra-colors-primary-500),
    var(--chakra-colors-primary-500)
  );
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: 0.3s;
}

.slider.dark::-webkit-slider-thumb {
  background: #fff;
}

.slider.light::-webkit-slider-thumb {
  background: var(--chakra-colors-primary-500);
}

.slider::-webkit-slider-thumb:active {
  transform: scale(1.12);
}

.slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
