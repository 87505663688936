#whatsapp-dialog > div {
  background-color: #128c7e;
  color: white;
}

#atendimentoDialogId > .p-dialog-header {
  background-color: #f0f2f5;
}

#atendimentoDialogId > div > div > div > div > .field {
  margin-bottom: 0px;
}

@keyframes pulseGreen {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(56, 161, 105, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(56, 161, 105, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(56, 161, 105, 0);
  }
}

@keyframes pulseBlue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(32, 44, 95, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(32, 44, 95, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(32, 44, 95, 0);
  }
}
