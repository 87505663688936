/* POPOVER */
.popover-container {
  position: relative;
}
.popover-popup {
  min-width: 250px;
  opacity: 0;
  /* background: #ffffff; */
  position: absolute;
  z-index: 10000;
  border-radius: 4px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-200);
  transform-origin: 100% 100%;
  right: 50%;
  transform: translate(50%, 100%);
  bottom: -16px;
}
.popover-trigger[aria-disabled="true"] {
  opacity: 0.4;
  pointer-events: none;
}
.popover-arrow {
  position: absolute;
  z-index: -1;
  top: 0;
  transform: translate(50%, calc(-100% + 1px));
}
.popover-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 18px 18px 17px 18px;
  border-bottom: 1px solid #eaeaea;
}
.popover-container .popover-close-button {
  width: 20px;
  min-width: 20px;
  height: 20px;
  transform: translate(0, -3px);
}
.popover-content {
  padding: 8px;
}
